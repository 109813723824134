// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataPlayerSite = createAsyncThunk('appUsers/getAllDataPlayerSite', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/players/all',  { params: { id} })
  return response.data
})

export const getDataPlayerSite = createAsyncThunk('appUsers/getDataPlayerSite', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/players/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getPlayerTypes = createAsyncThunk('appUsers/getPlayerTypes', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/player/types')
  return response.data
})
export const getMultiZones = createAsyncThunk('appUsers/getMultiZones', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/player/multi/zones')
  return response.data
})

export const getClients = createAsyncThunk('appUsers/getClients', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/all', { params: { id} })
  return response.data.clients
})

export const getGroupes = createAsyncThunk('appUsers/getGroupes', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/groupes/all')
  return response.data
})

export const getSitesClient = createAsyncThunk('appUsers/getSitesClient', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/client/sites/all', { params: { id} })
  return response.data
})
export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getPlayer = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const migratePlayer = createAsyncThunk('appUsers/migratePlayer', async (data, { dispatch, getState }) => {
  console.log("app")

  console.log(data)

  await axios.post('https://apiv3.opentlv.com/api/v3/site/player/move', data)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlayerSite(getState().playersSite.params))
  
  return data

})
export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})
export const addPlayer = createAsyncThunk('appUsers/addPlayer', async (site, { dispatch, getState }) => {
  console.log("app")

  site.id_site = getState().clients.selectedSite.id
  console.log(site)


  await axios.post('https://apiv3.opentlv.com/api/v3/site/player/add', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlayerSite(getState().playersSite.params))
  return site
})

export const editPlayer = createAsyncThunk('appUsers/editPlayer', async (site, { dispatch, getState }) => {
  console.log("app")

  site.id_site = getState().clients.selectedSite.id
  console.log(site)


  await axios.post('https://apiv3.opentlv.com/api/v3/site/player/edit', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlayerSite(getState().playersSite.params))
  return site
})
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    multiZones: [],
    sites: [],
    clients: [],
    groupes: []

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataPlayerSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataPlayerSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getPlayerTypes.fulfilled, (state, action) => {
        state.playerTypes = action.payload
      })
      .addCase(getMultiZones.fulfilled, (state, action) => {
        state.multiZones = action.payload
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.clients = action.payload
      })
      .addCase(getGroupes.fulfilled, (state, action) => {
        state.groupes = action.payload
      })
      .addCase(getSitesClient.fulfilled, (state, action) => {
        state.sites = action.payload
      })

  }
})

export default appClientsSlice.reducer
