// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataClients = createAsyncThunk('appUsers/getAllDataClients', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/all', { params: { id}})
  return response.data
})

export const getDataClients = createAsyncThunk('appUsers/getDataClients', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getAllDataClientsPlaylists = createAsyncThunk('appUsers/getAllDataClientsPlaylists', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/playlists/all')
  return response.data
})

export const getDataClientsPlaylists = createAsyncThunk('appUsers/getDataClientsPlaylists', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/playlists/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getDataOptions = createAsyncThunk('appUsers/getDataOptions', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getDataPlaylists = createAsyncThunk('appUsers/getDataPlaylists', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getClient = createAsyncThunk('appUsers/getClient', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/clients/client', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getSite = createAsyncThunk('appUsers/getSite', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getOption = createAsyncThunk('appUsers/getOption', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/option', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getChannel = createAsyncThunk('appUsers/getChannel', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/channel', { params: { id} })
  console.log(response.data)
  return response.data
})
export const getCatalog = createAsyncThunk('appUsers/getCatalog', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/catalog', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getPlaylist = createAsyncThunk('appUsers/getPlaylist', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/playlist', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getConfig = createAsyncThunk('appUsers/getConfig', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/config', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getHotlinksSlots = createAsyncThunk('appUsers/getHotlinksSlots', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/hotlinks/slots', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getHotlinksPlaylists = createAsyncThunk('appUsers/getHotlinksPlaylists', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/hotlinks/playlists', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getHotlinksZones = createAsyncThunk('appUsers/getHotlinksZones', async (id, {getState}) => {
 
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/hotlinks/zones', { params: { id, site_id : getState().clients.selectedSite.id} })
  console.log(response.data)
  return response.data
})


export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})
export const addClient = createAsyncThunk('appUsers/addClient', async (site, { dispatch, getState }) => {
  
  await axios.post('https://apiv3.opentlv.com/api/v3/clients/addclient', site)

  await dispatch(getDataClients(getState().clients.params))
  
  return site
})

export const deleteClient = createAsyncThunk('appUsers/deleteClient', async (id, { dispatch, getState}) => {
  console.log("getState()")
  console.log(getState)

  await axios.delete('https://apiv3.opentlv.com/api/v3/clients/client/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataClients(getState().clients.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedGroupe: null,
    selectedClient: null,
    playlists: [],
    hotlinks: [],
    channels: [],
    playlist: [],
    slots:[],
    zones:[]

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataClients.fulfilled, (state, action) => {
        state.allData = action.payload.clients
        state.selectedGroupe = action.payload.groupe


      })
      .addCase(getDataClients.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.selectedClient = action.payload.client
        state.selectedGroupe = action.payload.groupe

      })
      .addCase(getSite.fulfilled, (state, action) => {
          console.log(action.payload)
        state.selectedSite = action.payload.site
        state.selectedClient = action.payload.client
        state.playlists = action.payload.playlists
        state.hotlinks = action.payload.hotlinks
        state.channels = action.payload.channels
        

      })
    .addCase(getHotlinksPlaylists.fulfilled, (state, action) => {
      state.playlist = action.payload.playlist
    })
    .addCase(getHotlinksSlots.fulfilled, (state, action) => {
      state.slots = action.payload.slots
    })
    .addCase(getHotlinksZones.fulfilled, (state, action) => {
      state.zones = action.payload.zones
    })
      .addCase(getChannel.fulfilled, (state, action) => {
        state.selectedChannel = action.payload
      })
      .addCase(getCatalog.fulfilled, (state, action) => {
        state.selectedCatalog = action.payload
      })
      .addCase(getPlaylist.fulfilled, (state, action) => {
        state.selectedPlaylist = action.payload
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.selectedConfig = action.payload
      })
  }
})

export default appClientsSlice.reducer
